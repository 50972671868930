import React from 'react';
import { Box, Text, Flex } from 'rebass';
import { Container } from '../../../components/container';
import { BackToPOSLink } from '../../components/Buttons'

const HeroCardGetAccess = () =>  (
    <Box width="376px" ml="auto">
      <Flex px="28px" pt="150px" flexDirection="column" alignItems="center">
          <Flex pt="18px" flexDirection="column">
              <BackToPOSLink />
          </Flex>
        </Flex>
    </Box>
  )

const HeroTitle = ({ children }) => (
  <Text
    p="0"
    pt="120px"
    pb="50px"
    color="white"
    fontSize="29px"
    fontWeight="600"
    lineHeight="0.86"
    letterSpacing="normal"
  >
    {children}
  </Text>
);

const HeroSubtitle = ({ children }) => (
  <Text
    m="0"
    pb={['21px', 4, 5]}
    color="white"
    fontSize="52px"
    fontWeight="900"
    lineHeight="1.1"
    letterSpacing="-1.91px"
  >
    {children}
  </Text>
);

const VerticalYellowLine = () => (
  <Box
    m="0"
    py="21px"
    width="12px"
    height="504px"
    bg="maize"
    css={{
      borderRadius: '14px',
      display: 'inline-block',
    }}
  />
);

const HeroHeader = ({ title, subtitle, children }) => (
  <>
    <Flex flexWrap="wrap" flexDirection="row" width={1}>
      <VerticalYellowLine />
      <Flex
        width={[0.5, 0.55, 0.9]}
        pl="40px"
        flexWrap="wrap"
        flexDirection="column"
      >
        <HeroTitle>{title}</HeroTitle>
        <HeroSubtitle>{subtitle}</HeroSubtitle>
      </Flex>
      {children}
    </Flex>
  </>
);

const Hero = ({ title, subtitle }) => (
  <>
    <Container>
      <Flex
        pt="18px"
        flexWrap="wrap"
        flexDirection="row"
        alignItems="flex-start"
      >
        <HeroHeader title={title} subtitle={subtitle}>
          <HeroCardGetAccess />
        </HeroHeader>
      </Flex>
    </Container>
  </>
);

export default Hero;
